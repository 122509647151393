import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GroupPic from "../../images/kfitzah.jpg"
import Teaching from "../../images/kfitzah-teaching.jpg"
import Happy from "../../images/kfitzah-happy.jpeg"

const Kfitzah = () => (
  <Layout>
    <SEO title="coding bootcamp" />
    <div className="c12 title main">
        <h1>Kfitzah - Coding Bootamp</h1>
    </div>
    <div className="c12 main">
        <div className="summary">
            <div className="summary-section">
                <div className="section summary-item">
                    <h5 className="subtitle">Overview</h5>
                    <p>
                        An 8-week long coding bootcamp course in collaboration with the <a target="_blank" className="kfitzah-link" href="https://www.ardc-israel.org/">African Refugee Development Center (ARDC)</a> in Israel.
                    </p>
                </div>

                <div className="section summary-item">
                    <h5 className="subtitle">Project Type</h5>
                    <p>Voluntary, outside of work</p>
                </div>

                <div className="section summary-item">
                    <h5 className="subtitle">Roles</h5>
                    <p>Teacher</p>
                    <p>Curriculum designer</p>
                    <p>Founding team member</p>
                </div>
                <div className="section summary-item">
                    <h5 className="subtitle">Timeline</h5>
                    <p>Started planning August 2017</p>
                    <p>Bootcamp held between February-April 2018</p>
                </div>
                <div className="section summary-item">
                    <h5 className="subtitle">Team</h5>
                    <p>Eden A, Allie V, Valentine G, Arie B, Max P, Joachim H, Talia H</p>
                </div>
                <div className="section summary-item">
                    <p>🎥 <a target="_blank" href="https://video.i24news.tv/details/_6121801365001?lang=en" className="kfitzah-link">In the news: Jan. 11th, 2020</a></p>
                    <p>🎥 <a target="_blank" href="https://www.facebook.com/kfitzah/videos/320772271765108/" className="kfitzah-link">Teaching clip</a></p>
                </div>
            </div>
            <img src={GroupPic} className="summary-section"/>
        </div>
    </div>
    <div className="grid">
        <div className="c6 divider"></div>
        <div className="c6">
            <div className="section">
                <h5 className="subtitle">Background</h5>
                <p>From 2006-2012, a large influx of African asylum seekers arrived in Israel, with many having been trafficked through the Sinai Peninsula.</p>
                <br/>
                <p>Around 35,000 asylum seekers from Africa live in Israel as of October 2018. Of those, more than 70% fled from Eritrea and nearly 20% came from Sudan <a target="_blank" href="https://www.gov.il/BlobFolder/reports/foreign_workers_report_q2_2016/he/q2_2016.pdf" className="kfitzah-link">(source)</a>.</p>
                <br/>
                <p>Many of these people have been living in Israel for more than 10 years.</p>
            </div>

            <div className="section">
                <h5 className="subtitle">Problem</h5>
                <p>Many of these refugees and asylum seekers live in southern Tel Aviv. We are <i>living</i> side-by-side, yet we aren't <i>working</i> side-by-side. Why? </p>
            </div>
            <div className="c6 divider"></div>
            <div className="section">
                <h5 className="subtitle">Process</h5>
                <p>After completing Israel Tech Challenge's (ITC) <a target="_blank" href="https://www.itc.tech/full-stack-development-2/" className="kfitzah-link">coding bootcamp</a>, a group of us got together and wondered how we could give back.</p>
                <br/>
                <p>We met with the leadership team of ARDC to understand their community's needs and see if there was a way we could help. Through these discussions, we learned that there are some high achieving, tech-minded individuals in the community who were eager to learn coding.</p>
                <br/>
                <p>Kfitzah ("jump" in Hebrew) was an initiative to empower our local, underrepresented communities in Tel Aviv with basic programming skills.</p>
                <br/>
                <p>To get started, the group split in half to focus our efforts on both recruiting and screening candidates, and developing the curriculum.</p>
            </div>
            <div className="section">
                <h5 className="subtitle">Curriculum Design</h5>
                <h2 className="kfitzah-title">Starting from 0</h2>
                <p>Arie and I worked on designing the curriculum. After understanding the needs from ARDC, we discussed and worked on setting realistic expectations for an 8-week (24 hours total) program. I reached out to the lead course instructor of our coding bootcamp who had developed the course content himself. We brainstormed the course content together taking into account the current level of the participants' knowledge and the amount of material we could realistically cover in 8 weeks.</p>
                <br/>
                <p>We debated between two course focuses: 1) basics of web development, or 2) basics of programming.</p>
                <br/>
                <p>After discussing with ARDC, we determined that it would be most useful to the participants to have basic web development knowledge so that they can walk away from the course having gained immediate, tangible skills like being able to create their own websites.</p>
                <br/>
                <img src={Teaching} className="img"/>
                <br/>
                <p>Check out the intro lecture slides:</p>
                <iframe className="kfitzah-slides" src="https://docs.google.com/presentation/d/e/2PACX-1vTxy3ESX920cfrjhxJrz-9_GYylhKPOXMqgvrV6Nf72in_-fkPepEH24FsQ1jC5e3aC21yuMAeRRXJq/embed?start=false&loop=true&delayms=3000" frameborder="0" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
            </div>
            <div className="section">
                <h5 className="subtitle">Participant Selection</h5>
                <h2 className="kfitzah-title">Interviews</h2>
                <p>The rest of the group focused on interviewing candidates to determine their eligibility.</p>
                <br/>
                <p>We all brainstormed the types of questions to ask including both HR-type questions and technical questions. For example:</p>
                <br/>
                <ul>
                    <li>Tell me about a time you taught yourself something: what did you teach yourself? What were the steps you took to learn? What was your biggest take away?</li>
                    <li>Without writing anything, describe how you'd convert a word from selector-case to camelCase (e.g. from “background-color” to “backgroundColor”, ”blue" to "Blue”)</li>
                </ul>
                <br/>
                <p>Another factor that was taken into consideration was their level of English and previous experience using computers.</p>
            </div>
            <div className="section">
                <h2 className="kfitzah-title">Mentor Match</h2>
                <p>We also sought out mentors and matched them with each participant. The mentors helped the students with the assignments and guided them during the week in between their classes with us.</p>
            </div>
            {/* <div className="section">
                <h2 className="kfitzah-title">Challenges</h2>
                <ul>
                    <li>Physical space to hold class - ITC donated their space for us to use on the weekend</li>
                    <li>Individuals may not have their own computer - found organization, ITC, to lend laptops for free</li>
                    <li>English skill level</li>
                    <li>Computer literacy</li>
                    <li>Age differences - some participants hadn't been a student in over 20 years</li>
                </ul>
            </div> */}
            <div className="c6 divider"></div>
            <div className="section">
                <h5 className="subtitle">Outcome</h5>
                <h2 className="kfitzah-title">Sustainable Change</h2>
                <p>Two of our students received scholarships to attend coding bootcamps in Tel Aviv after completing our course.</p>
                <img src={Happy} className="img"/>
                <br/>
                <p>The success of Kfitzah led ARDC to adapt and build out this program. They now offer it as one of their core services: <a target="_blank" href="https://www.ardc-israel.org/restart-coding-program" className="kfitzah-link">ReStart</a>.</p>
                <br/>
                <p>The first year of ReStart helped 32 graduates. From the program's website:</p>
                <br/>
                <div className="challenge-statement">
                    <h4>"As a result of these efforts, the desire to learn coding within the community is substantially increasing. Some of the graduates of these courses are now teaching coding to community members, in their own groups as well."</h4>
                </div>
            </div>
        </div>
    </div>
    <div className="bottom-nav">
        <Link to="/portfolio/design-thinking">Design Workshops & Consulting</Link>
        <Link to="/portfolio/hackathon">Hack for Olim Hackathon</Link>
        <Link to="/portfolio/what-the-tech">What the Tech</Link>
        <Link to="/portfolio/art">Art</Link>
        <Link to="/">Home</Link>
    </div>
  </Layout>
)

export default Kfitzah